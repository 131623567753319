@import url("https://fonts.googleapis.com/css?family=Ek+Mukta:300,400,500,600,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Archivo+Narrow&subset=latin-ext");
@media (min-width: 1500px) {
  .container--xlg, .container--xxlg {
    width: 1470px; } }

@media (min-width: 1800px) {
  .container--xxlg {
    width: 1770px; } }

.col-xlg-1, .col-xxlg-1, .col-xlg-2, .col-xxlg-2, .col-xlg-3, .col-xxlg-3, .col-xlg-4, .col-xxlg-4, .col-xlg-5, .col-xxlg-5, .col-xlg-6, .col-xxlg-6, .col-xlg-7, .col-xxlg-7, .col-xlg-8, .col-xxlg-8, .col-xlg-9, .col-xxlg-9, .col-xlg-10, .col-xxlg-10, .col-xlg-11, .col-xxlg-11, .col-xlg-12, .col-xxlg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 1500px) {
  .col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11, .col-xlg-12 {
    float: left; }
  .col-xlg-1 {
    width: 8.3333333333%; }
  .col-xlg-2 {
    width: 16.6666666667%; }
  .col-xlg-3 {
    width: 25%; }
  .col-xlg-4 {
    width: 33.3333333333%; }
  .col-xlg-5 {
    width: 41.6666666667%; }
  .col-xlg-6 {
    width: 50%; }
  .col-xlg-7 {
    width: 58.3333333333%; }
  .col-xlg-8 {
    width: 66.6666666667%; }
  .col-xlg-9 {
    width: 75%; }
  .col-xlg-10 {
    width: 83.3333333333%; }
  .col-xlg-11 {
    width: 91.6666666667%; }
  .col-xlg-12 {
    width: 100%; }
  .col-xlg-pull-0 {
    right: auto; }
  .col-xlg-pull-1 {
    right: 8.3333333333%; }
  .col-xlg-pull-2 {
    right: 16.6666666667%; }
  .col-xlg-pull-3 {
    right: 25%; }
  .col-xlg-pull-4 {
    right: 33.3333333333%; }
  .col-xlg-pull-5 {
    right: 41.6666666667%; }
  .col-xlg-pull-6 {
    right: 50%; }
  .col-xlg-pull-7 {
    right: 58.3333333333%; }
  .col-xlg-pull-8 {
    right: 66.6666666667%; }
  .col-xlg-pull-9 {
    right: 75%; }
  .col-xlg-pull-10 {
    right: 83.3333333333%; }
  .col-xlg-pull-11 {
    right: 91.6666666667%; }
  .col-xlg-pull-12 {
    right: 100%; }
  .col-xlg-push-0 {
    left: auto; }
  .col-xlg-push-1 {
    left: 8.3333333333%; }
  .col-xlg-push-2 {
    left: 16.6666666667%; }
  .col-xlg-push-3 {
    left: 25%; }
  .col-xlg-push-4 {
    left: 33.3333333333%; }
  .col-xlg-push-5 {
    left: 41.6666666667%; }
  .col-xlg-push-6 {
    left: 50%; }
  .col-xlg-push-7 {
    left: 58.3333333333%; }
  .col-xlg-push-8 {
    left: 66.6666666667%; }
  .col-xlg-push-9 {
    left: 75%; }
  .col-xlg-push-10 {
    left: 83.3333333333%; }
  .col-xlg-push-11 {
    left: 91.6666666667%; }
  .col-xlg-push-12 {
    left: 100%; }
  .col-xlg-offset-0 {
    margin-left: 0%; }
  .col-xlg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-xlg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-xlg-offset-3 {
    margin-left: 25%; }
  .col-xlg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-xlg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-xlg-offset-6 {
    margin-left: 50%; }
  .col-xlg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-xlg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-xlg-offset-9 {
    margin-left: 75%; }
  .col-xlg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-xlg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-xlg-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1800px) {
  .col-xxlg-1, .col-xxlg-2, .col-xxlg-3, .col-xxlg-4, .col-xxlg-5, .col-xxlg-6, .col-xxlg-7, .col-xxlg-8, .col-xxlg-9, .col-xxlg-10, .col-xxlg-11, .col-xxlg-12 {
    float: left; }
  .col-xxlg-1 {
    width: 8.3333333333%; }
  .col-xxlg-2 {
    width: 16.6666666667%; }
  .col-xxlg-3 {
    width: 25%; }
  .col-xxlg-4 {
    width: 33.3333333333%; }
  .col-xxlg-5 {
    width: 41.6666666667%; }
  .col-xxlg-6 {
    width: 50%; }
  .col-xxlg-7 {
    width: 58.3333333333%; }
  .col-xxlg-8 {
    width: 66.6666666667%; }
  .col-xxlg-9 {
    width: 75%; }
  .col-xxlg-10 {
    width: 83.3333333333%; }
  .col-xxlg-11 {
    width: 91.6666666667%; }
  .col-xxlg-12 {
    width: 100%; }
  .col-xxlg-pull-0 {
    right: auto; }
  .col-xxlg-pull-1 {
    right: 8.3333333333%; }
  .col-xxlg-pull-2 {
    right: 16.6666666667%; }
  .col-xxlg-pull-3 {
    right: 25%; }
  .col-xxlg-pull-4 {
    right: 33.3333333333%; }
  .col-xxlg-pull-5 {
    right: 41.6666666667%; }
  .col-xxlg-pull-6 {
    right: 50%; }
  .col-xxlg-pull-7 {
    right: 58.3333333333%; }
  .col-xxlg-pull-8 {
    right: 66.6666666667%; }
  .col-xxlg-pull-9 {
    right: 75%; }
  .col-xxlg-pull-10 {
    right: 83.3333333333%; }
  .col-xxlg-pull-11 {
    right: 91.6666666667%; }
  .col-xxlg-pull-12 {
    right: 100%; }
  .col-xxlg-push-0 {
    left: auto; }
  .col-xxlg-push-1 {
    left: 8.3333333333%; }
  .col-xxlg-push-2 {
    left: 16.6666666667%; }
  .col-xxlg-push-3 {
    left: 25%; }
  .col-xxlg-push-4 {
    left: 33.3333333333%; }
  .col-xxlg-push-5 {
    left: 41.6666666667%; }
  .col-xxlg-push-6 {
    left: 50%; }
  .col-xxlg-push-7 {
    left: 58.3333333333%; }
  .col-xxlg-push-8 {
    left: 66.6666666667%; }
  .col-xxlg-push-9 {
    left: 75%; }
  .col-xxlg-push-10 {
    left: 83.3333333333%; }
  .col-xxlg-push-11 {
    left: 91.6666666667%; }
  .col-xxlg-push-12 {
    left: 100%; }
  .col-xxlg-offset-0 {
    margin-left: 0%; }
  .col-xxlg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-xxlg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-xxlg-offset-3 {
    margin-left: 25%; }
  .col-xxlg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-xxlg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-xxlg-offset-6 {
    margin-left: 50%; }
  .col-xxlg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-xxlg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-xxlg-offset-9 {
    margin-left: 75%; }
  .col-xxlg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-xxlg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-xxlg-offset-12 {
    margin-left: 100%; } }

.col-xs-5ths, .col-sm-5ths, .col-md-5ths, .col-lg-5ths, .col-xlg-5ths, .col-xxlg-5ths {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-5ths {
  float: left;
  width: 20%; }

@media (min-width: 768px) {
  .col-sm-5ths {
    float: left;
    width: 20%; } }

@media (min-width: 993px) {
  .col-md-5ths {
    float: left;
    width: 20%; } }

@media (min-width: 1200px) {
  .col-lg-5ths {
    float: left;
    width: 20%; } }

@media (min-width: 1500px) {
  .col-xlg-5ths {
    float: left;
    width: 20%; } }

@media (min-width: 1800px) {
  .col-xxlg-5ths {
    float: left;
    width: 20%; } }

.col-inside-full-height {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.row-xs-height {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%; }

.col-xs-height {
  display: table-cell;
  float: none;
  height: 100%; }

.col-xs-top {
  vertical-align: top; }

.col-xs-middle {
  vertical-align: middle; }

.col-xs-bottom {
  vertical-align: bottom; }

@media (min-width: 768px) {
  .row-sm-height {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .col-sm-height {
    display: table-cell;
    float: none;
    height: 100%; }
  .col-sm-top {
    vertical-align: top; }
  .col-sm-middle {
    vertical-align: middle; }
  .col-sm-bottom {
    vertical-align: bottom; } }

@media (min-width: 993px) {
  .row-md-height {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .col-md-height {
    display: table-cell;
    float: none;
    height: 100%; }
  .col-md-top {
    vertical-align: top; }
  .col-md-middle {
    vertical-align: middle; }
  .col-md-bottom {
    vertical-align: bottom; } }

@media (min-width: 1200px) {
  .row-lg-height {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .col-lg-height {
    display: table-cell;
    float: none;
    height: 100%; }
  .col-lg-top {
    vertical-align: top; }
  .col-lg-middle {
    vertical-align: middle; }
  .col-lg-bottom {
    vertical-align: bottom; } }

@media (min-width: 1500px) {
  .row-xlg-height {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .col-xlg-height {
    display: table-cell;
    float: none;
    height: 100%; }
  .col-xlg-top {
    vertical-align: top; }
  .col-xlg-middle {
    vertical-align: middle; }
  .col-xlg-bottom {
    vertical-align: bottom; } }

@media (min-width: 1800px) {
  .row-xxlg-height {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .col-xxlg-height {
    display: table-cell;
    float: none;
    height: 100%; }
  .col-xxlg-top {
    vertical-align: top; }
  .col-xxlg-middle {
    vertical-align: middle; }
  .col-xxlg-bottom {
    vertical-align: bottom; } }

.col-xs-del-left {
  padding-left: 0; }

.col-xs-del-right {
  padding-right: 0; }

.col-xs-del-both {
  padding-left: 0;
  padding-right: 0; }

@media (min-width: 768px) {
  .col-sm-del-left {
    padding-left: 0; }
  .col-sm-del-right {
    padding-right: 0; }
  .col-sm-del-both {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 993px) {
  .col-md-del-left {
    padding-left: 0; }
  .col-md-del-right {
    padding-right: 0; }
  .col-md-del-both {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1200px) {
  .col-lg-del-left {
    padding-left: 0; }
  .col-lg-del-right {
    padding-right: 0; }
  .col-lg-del-both {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1500px) {
  .col-xlg-del-left {
    padding-left: 0; }
  .col-xlg-del-right {
    padding-right: 0; }
  .col-xlg-del-both {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1800px) {
  .col-xxlg-del-left {
    padding-left: 0; }
  .col-xxlg-del-right {
    padding-right: 0; }
  .col-xxlg-del-both {
    padding-left: 0;
    padding-right: 0; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

a {
  transition: all 0.25s ease 0.1s;
  color: inherit;
  outline: none;
  box-shadow: none; }

a, a:hover, a:focus {
  text-decoration: none;
  cursor: pointer; }

button {
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer; }

.button {
  display: inline-block;
  margin: 0 0 15px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer; }

ul {
  margin: 0;
  padding: 0; }

img {
  max-width: 100%; }

input,
textarea,
select {
  display: block;
  width: 100%;
  outline: none;
  box-shadow: none; }

.u-full-width {
  width: 100%; }

.u-grayscale {
  transition: all 0.3s ease 0.1s;
  filter: gray;
  /* IE 6-9 */
  filter: grayscale(100%);
  -webkit-backface-visibility: hidden;
  transform: translate3d(0px, 0px, 0px); }
  .u-grayscale:hover {
    filter: none; }

.u-grayscale-static {
  filter: gray;
  /* IE 6-9 */
  filter: grayscale(100%);
  -webkit-backface-visibility: hidden;
  transform: translate3d(0px, 0px, 0px); }

.u-faded-section {
  transition: 1s ease;
  opacity: 0; }

.u-faded-section.faded-in {
  opacity: 1; }

.u-ratio {
  position: relative;
  height: 0; }
  .u-ratio__16by9 {
    padding-bottom: 56.25%; }
  .u-ratio__16by10 {
    padding-bottom: 62.5%; }
  .u-ratio__4by3 {
    padding-bottom: 75%; }
  .u-ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.alert {
  margin: 0 0 15px;
  border: 0;
  border-radius: 2px;
  padding: 15px 15px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  text-shadow: 0 0 1px #3e3e3e; }
  .alert.alert-success {
    background: #26a65b;
    border-bottom: 1px solid #1f894b; }
  .alert.alert-danger {
    background: #cf000f;
    border-bottom: 1px solid #ab000c; }
  .alert.alert-warning {
    background: #e87e04;
    border-bottom: 1px solid #c56b03; }
  .alert.alert-info {
    background: #3a539b;
    border-bottom: 1px solid #304581; }
  .alert .close {
    outline: none;
    opacity: 1;
    top: -4px;
    right: 0;
    font-size: 26px;
    color: #fff;
    font-weight: 300;
    text-shadow: 0 0 1px #3e3e3e; }

.b-cookies {
  transition: all .4s ease;
  z-index: 9000;
  position: fixed;
  top: initial;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(250, 87, 94, 0.9);
  margin: 0;
  padding: 30px 0 20px 0;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.3);
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 1.5em;
  text-align: justify; }
  .b-cookies__left {
    float: left;
    width: 60px; }
  .b-cookies__right {
    float: right;
    width: 150px;
    text-align: right; }
  .b-cookies__center {
    overflow: hidden;
    padding: 0 30px; }
  .b-cookies__button {
    transition: all 0.25s ease;
    display: inline-block;
    background: #fff;
    margin: 0;
    border: none;
    border-radius: 2px;
    padding: 0 20px;
    box-shadow: 0 3px 5px #4e4e4e;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    line-height: 35px; }
    .b-cookies__button:hover {
      background: #fff;
      color: #000;
      margin: 1px 0 -1px;
      box-shadow: none; }
  .b-cookies.closed {
    transform: scale(0.8);
    opacity: 0;
    visibility: hidden; }
  @media (max-width: 767px) {
    .b-cookies__left {
      display: none; }
    .b-cookies__right {
      float: none;
      width: 100%;
      margin-bottom: 15px; }
    .b-cookies__center {
      float: none;
      padding: 0; } }

.main-nav {
  transition: all .3s ease .1s;
  line-height: 65px; }
  .main-nav ul {
    margin: 0;
    padding: 0;
    font-size: 0; }
  .main-nav ul li {
    vertical-align: bottom;
    list-style: none;
    position: relative; }
  .main-nav ul li a {
    position: relative;
    display: block;
    cursor: pointer; }
  .main-nav ul ul {
    transition: all .25s ease .4s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    z-index: 211;
    min-width: 100%; }
  .main-nav > ul > li {
    display: inline-block;
    vertical-align: middle; }
  .main-nav > ul > li > a {
    font-size: 16px;
    color: #363636;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
    padding: 0 17px; }
  .main-nav > ul > li > a:hover,
  .main-nav > ul > li > a:focus,
  .main-nav > ul > li > a.current {
    background: #75affb;
    color: #fff; }
  .main-nav > ul > li > a.social {
    margin: 0 3px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    line-height: 46px;
    text-align: center;
    width: 46px;
    height: 46px;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0; }
    .main-nav > ul > li > a.social:hover {
      background: #fa575e;
      border-color: #fa575e;
      color: #fff; }
    .main-nav > ul > li > a.social.fb:hover {
      background: #3b5998;
      border-color: #3b5998;
      color: #fff; }
  .main-nav--js-active {
    line-height: 50px; }
    .main-nav--js-active > ul > li > a.social {
      background: #fa575e;
      border-color: #fa575e; }
  @media (max-width: 1199px) {
    .main-nav > ul > li > a {
      font-size: 14px;
      padding: 0 13px; }
    .main-nav > ul > li > a.social {
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      width: 40px;
      height: 40px; } }

.sidr {
  display: none; }

.sidr-toggle {
  display: none; }

@media (max-width: 992px) {
  .sidr {
    display: none;
    position: fixed;
    top: 0;
    z-index: 8998;
    width: 300px;
    height: 100%;
    overflow-x: hidden;
    border-right: 1px solid #5e5e5e;
    box-shadow: 0 0 10px #5e5e5e;
    padding: 65px 15px 15px;
    background: #fff; }
    .sidr .sidr-inner {
      padding: 15px 0;
      border-top: 1px solid #ededed;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25); }
      .sidr .sidr-inner:first-of-type {
        margin-top: 15px; }
    .sidr:before {
      content: "MENU";
      position: absolute;
      top: 44px;
      font-size: 24px;
      color: #363636;
      font-weight: 700;
      line-height: 24px;
      text-transform: uppercase; }
    .sidr.left {
      left: -300px;
      right: auto; }
      .sidr.left:before {
        left: 80px; }
    .sidr.right {
      left: auto;
      right: -300px; }
  .sidr-toggle {
    transition: none;
    display: block;
    position: fixed;
    top: 15px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    z-index: 8999;
    padding: 23px 10px;
    background: rgba(250, 87, 94, 0.8); }
    .sidr-toggle.left {
      left: 15px; }
    .sidr-toggle.right {
      right: 15px; }
    .sidr-toggle .tr.tr-lines {
      display: block;
      font-size: 0; }
      .sidr-toggle .tr.tr-lines .lines,
      .sidr-toggle .tr.tr-lines .lines:before,
      .sidr-toggle .tr.tr-lines .lines:after {
        transition: .4s;
        display: inline-block;
        width: 30px;
        height: 4px;
        border-radius: 2px;
        background: #fff; }
      .sidr-toggle .tr.tr-lines .lines {
        position: relative; }
      .sidr-toggle .tr.tr-lines .lines:before,
      .sidr-toggle .tr.tr-lines .lines:after {
        content: "";
        position: absolute;
        left: 0; }
      .sidr-toggle .tr.tr-lines .lines:before {
        top: 10px; }
      .sidr-toggle .tr.tr-lines .lines:after {
        top: -10px; }
    .sidr-toggle .tr.tr-x .lines:before,
    .sidr-toggle .tr.tr-x .lines:after {
      transform-origin: 50% 50%;
      transition: top 0.3s 0.6s ease,         transform 0.3s ease; }
    .sidr-toggle .tr.tr-x.active .lines {
      transition: background 0.3s 0s ease;
      background: transparent; }
    .sidr-toggle .tr.tr-x.active .lines:before,
    .sidr-toggle .tr.tr-x.active .lines:after {
      transition: top 0.3s ease,         transform 0.3s 0.5s ease;
      top: 0; }
    .sidr-toggle .tr.tr-x.active .lines:before {
      transform: rotate(45deg); }
    .sidr-toggle .tr.tr-x.active .lines:after {
      transform: rotate(-45deg); }
  .sidr-inner .main-nav {
    padding: 0; }
    .sidr-inner .main-nav ul {
      display: block; }
    .sidr-inner .main-nav ul ul {
      transition: none;
      visibility: visible;
      opacity: 1;
      position: static; }
    .sidr-inner .main-nav > ul > li {
      display: block;
      margin: 0; }
    .sidr-inner .main-nav > ul > li > a {
      font-size: 16px;
      color: #363636;
      font-weight: 400;
      line-height: 40px;
      text-transform: uppercase;
      height: 40px;
      margin: 0;
      padding: 0 15px; }
    .sidr-inner .main-nav > ul > li > a:hover,
    .sidr-inner .main-nav > ul > li > a:focus,
    .sidr-inner .main-nav > ul > li > a.current {
      background: #fa575e;
      color: #fff; }
    .sidr-inner .main-nav > ul > li > a.social {
      text-align: left;
      width: 100%;
      border-radius: 0;
      border: none; } }

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .pace .pace-progress {
    background: #fa575e;
    z-index: 8997;
    position: fixed;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px; }
  @media (max-width: 767px) {
    .pace {
      display: none; } }

.pace-inactive {
  display: none; }

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0; }

.pagination li a,
.pagination li span {
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 1em;
  text-align: center;
  margin: 0 2px;
  border-radius: 0;
  padding: 14px 0;
  color: #363636; }

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background: #fa575e;
  color: #fff; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  border-color: #fa575e;
  background: #fa575e;
  color: #fff; }

.ripple {
  position: relative;
  overflow: hidden;
  z-index: 1; }

.ripple-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden; }

.material-ink {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0.3; }

.material-ink.animate {
  animation: ripple 0.6s linear; }

@keyframes ripple {
  100% {
    transform: scale(2.5);
    opacity: 0; } }

#social-panel {
  position: absolute;
  z-index: 1039;
  display: block;
  top: 175px;
  right: 0; }
  #social-panel a {
    display: block; }
  #social-panel .social-link {
    transition: all 0.2s ease;
    position: relative;
    width: 70px;
    height: 50px;
    font-size: 2rem;
    color: #fff;
    font-weight: 400;
    line-height: 50px;
    text-align: center; }
    #social-panel .social-link img {
      position: relative;
      top: 0;
      left: 0; }
    #social-panel .social-link:hover {
      color: #fa575e; }
  #social-panel.secondary {
    top: 20px;
    width: 100%;
    text-align: center; }
    #social-panel.secondary a {
      display: inline-block; }
    #social-panel.secondary .social-link {
      display: inline-block;
      width: 35px; }

.standard-form .form-group {
  margin-bottom: 30px; }

.standard-form input,
.standard-form textarea,
.standard-form select {
  transition: all 0.25s ease;
  background: transparent;
  height: 45px;
  border: 1px solid #a7ccfc;
  border-radius: 30px;
  padding: 0 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 45px; }
  .standard-form input:-moz-placeholder,
  .standard-form textarea:-moz-placeholder,
  .standard-form select:-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .standard-form input::-moz-placeholder,
  .standard-form textarea::-moz-placeholder,
  .standard-form select::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .standard-form input:-ms-input-placeholder,
  .standard-form textarea:-ms-input-placeholder,
  .standard-form select:-ms-input-placeholder {
    color: #fff; }
  .standard-form input::-webkit-input-placeholder,
  .standard-form textarea::-webkit-input-placeholder,
  .standard-form select::-webkit-input-placeholder {
    color: #fff; }
  .standard-form input:focus,
  .standard-form textarea:focus,
  .standard-form select:focus {
    border-color: #fff; }

.standard-form textarea {
  resize: none;
  height: 150px;
  line-height: initial;
  padding: 20px; }

.standard-form input[type="submit"],
.standard-form button[type="submit"] {
  display: inline-block;
  width: initial;
  height: initial;
  min-width: initial;
  color: #363636;
  font-weight: 500;
  background: #fff;
  padding: 0 35px;
  border-radius: 30px; }
  .standard-form input[type="submit"]:hover,
  .standard-form button[type="submit"]:hover {
    background: #fff;
    padding: 0 40px;
    color: #363636; }

.standard-form .has-icon {
  background-position: top 12px right 20px;
  background-repeat: no-repeat;
  padding-right: 50px; }
  .standard-form .has-icon--name {
    background-image: url(../../img/site/icons/contact-name.png); }
  .standard-form .has-icon--mail {
    background-image: url(../../img/site/icons/contact-mail.png); }
  .standard-form .has-icon--text {
    background-image: url(../../img/site/icons/contact-text.png); }

.standard-form .required > label:after {
  content: " *"; }

.standard-form .has-error input,
.standard-form .has-error input[type="radio"] + label span,
.standard-form .has-error input[type="checkbox"] + label span,
.standard-form .has-error textarea,
.standard-form .has-error select {
  border-color: #cf000f; }

.standard-form .has-error input[type="radio"] + label,
.standard-form .has-error input[type="checkbox"] + label {
  color: #cf000f; }

.standard-form .has-error .error-tip {
  text-align: left;
  background: #cf000f;
  padding: 5px 15px;
  font-size: 11px;
  color: #fff;
  font-weight: 400;
  text-shadow: 0 0 1px #3e3e3e;
  border-radius: 15px; }

@media (max-width: 767px) {
  .standard-form .form-group {
    margin-bottom: 15px; } }

.newsletter-form {
  position: relative;
  font-size: 0;
  margin: 0 0 25px;
  border-radius: 0; }
  .newsletter-form input[type="text"],
  .newsletter-form input[type="submit"] {
    transition: all 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    height: 45px;
    background: #fff;
    border: 0 solid transparent;
    border-radius: 0;
    font-size: 14px;
    color: #363636;
    font-weight: 400;
    line-height: 45px; }
    .newsletter-form input[type="text"]:-moz-placeholder,
    .newsletter-form input[type="submit"]:-moz-placeholder {
      color: #363636;
      opacity: 1; }
    .newsletter-form input[type="text"]::-moz-placeholder,
    .newsletter-form input[type="submit"]::-moz-placeholder {
      color: #363636;
      opacity: 1; }
    .newsletter-form input[type="text"]:-ms-input-placeholder,
    .newsletter-form input[type="submit"]:-ms-input-placeholder {
      color: #363636; }
    .newsletter-form input[type="text"]::-webkit-input-placeholder,
    .newsletter-form input[type="submit"]::-webkit-input-placeholder {
      color: #363636; }
  .newsletter-form input[type="text"] {
    width: 100%;
    padding: 0 100px 0 10px; }
    .newsletter-form input[type="text"]:focus {
      border-color: #fa575e; }
  .newsletter-form input[type="submit"] {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    text-align: left;
    white-space: nowrap;
    width: auto;
    height: 45px;
    background-color: #fa575e;
    border-width: 0;
    padding: 0 45px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    line-height: 45px; }
    .newsletter-form input[type="submit"]:hover {
      opacity: .8; }

.title-wrapper {
  text-align: center;
  font-size: 0;
  margin: 20px 0 70px; }
  .title-wrapper__image {
    display: inline-block;
    vertical-align: top; }
  .title-wrapper__content {
    display: inline-block;
    vertical-align: top;
    padding-left: 15px;
    font-size: 21px;
    color: #363636;
    font-weight: 400;
    line-height: 1em;
    text-transform: uppercase;
    text-align: left; }
    .title-wrapper__content p {
      margin: 0 0 3px; }
  @media (max-width: 767px) {
    .title-wrapper {
      margin: 20px 0 35px; } }

.title {
  font-size: 30px;
  color: #fa575e;
  font-weight: 500;
  line-height: 1em;
  text-transform: initial; }

.headline {
  font-size: 18px;
  color: #fa575e;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0; }

.text, .feature__text {
  font-size: 15px;
  color: #363636;
  font-weight: 300;
  line-height: 2em;
  text-align: justify;
  margin-bottom: 15px; }
  .text--columns {
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 30px;
    column-gap: 30px; }
    @media (max-width: 767px) {
      .text--columns {
        -moz-column-count: 1;
        column-count: 1;
        -moz-column-gap: 0;
        column-gap: 0; } }
  .text ul, .feature__text ul {
    margin: 0 0 10px 0; }
  .text ul li, .feature__text ul li {
    display: block;
    position: relative;
    padding-left: 25px; }
    .text ul li:before, .feature__text ul li:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      width: 16px;
      height: 16px;
      border: 2px solid #fa575e;
      background: #fa575e;
      border-radius: 4px; }
  .text table thead tr th, .feature__text table thead tr th {
    padding: 20px 30px;
    font-size: 16px;
    color: #fa575e;
    font-weight: 700;
    line-height: 1em;
    white-space: nowrap;
    background: #f6f6f6; }
  .text table tbody tr td, .feature__text table tbody tr td {
    padding: 10px 30px;
    vertical-align: middle;
    font-size: 16px;
    color: #363636;
    font-weight: 400;
    line-height: 1em;
    border: none;
    border-bottom: 2px solid #f6f6f6; }

.button {
  transition: all 0.25s ease;
  border-radius: 15px;
  padding: 0 15px;
  font-size: 17px;
  color: #fff;
  font-weight: 400;
  line-height: 45px;
  text-transform: uppercase;
  text-align: center;
  min-width: 200px;
  background: #75affb; }
  .button:hover, .button:focus {
    background: #8ebdfc;
    color: #fff; }
  .button--prices {
    background: transparent url(../../img/site/icons/ad-round.png) no-repeat center bottom;
    font-size: 15px;
    color: #75affb;
    font-weight: 500;
    line-height: 2em;
    text-transform: uppercase;
    padding-bottom: 55px; }
    .button--prices:hover, .button--prices:focus {
      background: transparent url(../../img/site/icons/ad-round.png) no-repeat center bottom;
      color: #14b3c0; }
  @media (max-width: 767px) {
    .button {
      font-size: 14px;
      line-height: 30px;
      min-width: 150px; } }

.o-ib-wrapper {
  font-size: 0;
  text-align: center; }
  .o-ib-wrapper .o-ib {
    display: inline-block;
    vertical-align: top; }
  .o-ib-wrapper .o-ib-1_5 {
    width: 20%; }
  @media (max-width: 1199px) {
    .o-ib-wrapper .o-ib-1_5 {
      width: 33.3333333333%; } }
  @media (max-width: 767px) {
    .o-ib-wrapper .o-ib-1_5 {
      width: 100%; } }

html, body {
  font-family: "Ek Mukta", Arial, sans-serif;
  font-size: 12px;
  color: #363636; }

.top-wrapper {
  transition: all 0.3s ease 0.1s;
  position: fixed;
  z-index: 1038;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0.4) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#66FFFFFF', GradientType=1);
  padding: 18px; }
  .top-wrapper:before {
    content: "";
    transition: all 0.3s ease 0.1s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .top-wrapper .logo img {
    transition: all 0.3s ease 0.1s;
    max-height: 66px; }
  .top-wrapper__right {
    text-align: right; }
  .top-wrapper--js-active {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    padding-top: 10px;
    padding-bottom: 10px; }
    .top-wrapper--js-active:before {
      background: #fff; }
    .top-wrapper--js-active .logo img {
      max-height: 50px; }
  @media (max-width: 992px) {
    .top-wrapper {
      padding: 15px 0; }
      .top-wrapper .logo {
        text-align: center; }
        .top-wrapper .logo img {
          height: 50px; } }
  @media (max-width: 767px) {
    .top-wrapper {
      position: static; } }
  @media (max-width: 500px) {
    .top-wrapper .logo {
      text-align: right;
      margin-left: 70px; } }

.slider {
  position: relative; }
  .slider .slide {
    position: relative; }
    .slider .slide__image img {
      width: 100%; }
    .slider .slide__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .slider .slide__content-inner {
      width: 50%;
      padding-top: 220px;
      text-align: right; }
    .slider .slide__title {
      font-size: 35px;
      color: #14b3c0;
      font-weight: 700;
      line-height: 1.1em;
      margin-bottom: 15px; }
    .slider .slide__text {
      font-size: 24px;
      color: #fa575e;
      font-weight: 700;
      line-height: 1.1em;
      margin-bottom: 25px; }
  .slider .owl-dots {
    position: absolute;
    top: 40%;
    right: 40px;
    transform-origin: 100% 100%;
    transform: rotate(-90deg); }
    .slider .owl-dots .owl-dot span {
      transition: all .3s ease;
      background: #363636;
      margin: 0 5px;
      border: none;
      border-radius: 50%;
      width: 10px;
      height: 10px; }
    .slider .owl-dots .owl-dot span:hover,
    .slider .owl-dots .owl-dot.active span {
      background: #75affb; }
  @media (max-width: 1799px) {
    .slider .slide__content-inner {
      padding-top: 135px; }
    .slider .slide__title {
      font-size: 24px;
      margin-bottom: 10px; }
    .slider .slide__text {
      font-size: 18px;
      margin-bottom: 15px; } }
  @media (max-width: 767px) {
    .slider .slide__content-inner {
      padding-top: 50px;
      width: 100%;
      text-align: left;
      padding-left: 15px; }
    .slider .slide__title {
      font-size: 16px; }
    .slider .slide__text {
      font-size: 12px; } }
  @media (max-width: 500px) {
    .slider .slide__content-inner {
      padding-top: 15px; }
    .slider .slide__title {
      font-size: 12px; }
    .slider .slide__text {
      font-size: 12px; } }

.main-content {
  position: relative;
  z-index: 2;
  padding: 50px 0; }

.services {
  background: #f6f6f6;
  padding: 30px 0 0; }

.contact {
  background: #75affb;
  padding: 45px 0 20px; }

.promo-box-bottom {
  position: relative;
  z-index: 1;
  margin-top: -150px;
  overflow: hidden; }
  .promo-box-bottom:before {
    content: "";
    position: absolute;
    top: 0;
    right: 10%;
    z-index: -1;
    width: 620px;
    height: 415px;
    background: url(../../img/site/promo-box-bottom.png) no-repeat center; }
  .promo-box-bottom__content {
    position: relative;
    z-index: 1;
    padding: 150px 0 40px; }
  @media (max-width: 1499px) {
    .promo-box-bottom:before {
      right: 0; } }
  @media (max-width: 992px) {
    .promo-box-bottom:before {
      right: -200px; } }

.footer {
  padding: 25px 0;
  font-family: "Archivo Narrow";
  font-size: 15px;
  color: #363636;
  font-weight: 400;
  line-height: 1em; }
  .footer__right {
    text-align: right; }
  .footer img {
    display: inline-block;
    vertical-align: baseline;
    height: 14px;
    margin-left: 7px; }
  @media (max-width: 767px) {
    .footer {
      text-align: center; }
      .footer__right {
        text-align: center;
        padding-top: 10px; } }

.promo-box {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-top: 76px;
  border-radius: 20px;
  padding: 35px 50px 30px;
  background-image: linear-gradient(to right, #ed1c24 35%, #f36368 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFED1C24', endColorstr='#FFF36368', GradientType=1);
  text-align: center;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.4); }
  .promo-box:after {
    content: "";
    position: absolute;
    top: -76px;
    left: -76px;
    width: 152px;
    height: 152px;
    background: url(../../img/site/icons/alert-phone.png) no-repeat center;
    background-size: cover; }
  .promo-box__title {
    font-size: 32px;
    color: #fff;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
    margin-bottom: 8px; }
  .promo-box__subtitle {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    line-height: 1em;
    text-transform: uppercase;
    margin-bottom: 8px; }
  .promo-box__phone {
    font-size: 53px;
    color: #fff;
    font-weight: 700;
    line-height: 1em;
    margin-bottom: 0; }
    .promo-box__phone img {
      display: inline-block !important;
      width: initial !important;
      vertical-align: baseline;
      margin-right: 25px; }
    .promo-box__phone span {
      font-size: 19px;
      font-weight: 400; }
    .promo-box__phone a:hover, .promo-box__phone a:focus {
      color: #fff;
      text-decoration: underline; }
  @media (max-width: 1799px) {
    .promo-box {
      margin-top: 50px;
      padding: 25px 30px 20px; }
      .promo-box:after {
        top: -50px;
        left: -50px;
        width: 100px;
        height: 100px; }
      .promo-box__title {
        font-size: 24px; }
      .promo-box__subtitle {
        font-size: 14px; }
      .promo-box__phone {
        font-size: 40px; }
        .promo-box__phone img {
          margin-right: 15px;
          max-height: 35px; }
        .promo-box__phone span {
          font-size: 14px; } }
  @media (max-width: 500px) {
    .promo-box {
      margin-top: 30px;
      padding: 15px 20px 10px; }
      .promo-box:after {
        top: -30px;
        left: -30px;
        width: 60px;
        height: 60px; }
      .promo-box__title {
        font-size: 18px; }
      .promo-box__subtitle {
        font-size: 12px; }
      .promo-box__phone {
        font-size: 25px; }
        .promo-box__phone img {
          margin-right: 10px;
          max-height: 30px; }
        .promo-box__phone span {
          font-size: 12px; } }

.feature {
  margin-bottom: 30px; }
  .feature__image {
    height: 100px;
    text-align: center; }
  .feature__title {
    display: block;
    font-size: 16px;
    color: #fa575e;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 13px; }
  .feature__text {
    line-height: 1.7em; }
  @media (max-width: 992px) {
    .feature__text {
      text-align: center; } }

.service {
  text-align: center;
  margin-bottom: 80px; }
  .service__image {
    transition: all .3s ease .1s;
    display: inline-block;
    width: 125px;
    height: 125px;
    line-height: 125px;
    border-radius: 50%;
    border: 2px solid #fa575e;
    background: #fff; }
    .service__image:hover {
      margin: -3px 0 3px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); }
  .service__title {
    display: block;
    font-size: 18px;
    color: #5e5e5e;
    font-weight: 500;
    line-height: 1em;
    margin: 20px 0; }
    .service__title:hover {
      color: #fa575e; }
  @media (max-width: 767px) {
    .service {
      margin-bottom: 40px; } }

.table-responsive table {
  background-color: transparent;
  border-spacing: 10px;
  border-collapse: separate;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }
  .table-responsive table tbody tr td {
    background: #f6f6f6;
    border: none;
    padding: 15px 20px;
    font-size: 16px;
    color: #363636;
    font-weight: 400;
    line-height: 1em; }
    .table-responsive table tbody tr td span {
      font-size: 14px;
      color: #75affb; }
  .table-responsive table tbody tr td:first-of-type,
  .table-responsive table tbody tr td:last-of-type {
    text-align: center; }
  .table-responsive table tbody tr td:last-of-type {
    font-weight: 600; }

.map-wrapper {
  margin-bottom: 40px; }

.map {
  width: 100%;
  height: 350px; }
  @media (max-width: 767px) {
    .map {
      height: 250px; } }

/*# sourceMappingURL=master.css.map */
